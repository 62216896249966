/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Container, jsx } from "theme-ui"
import { graphql, PageProps } from "gatsby"

import type { WarrantyInformationQuery } from "../../graphql-types"

import Layout from "components/Layout"
import MdContent from "components/common/MdContent"

interface WarrantyInformationProps extends PageProps {
  data: WarrantyInformationQuery
}

const WarrantyInformation = ({ data, ...props }: WarrantyInformationProps) => {
  return (
    <Layout seo={data.strapiWarrantyInformation?.seo} location={props.location}>
      <Box as="article" sx={{ my: [12, null, 24] }}>
        <Container variant="layout.narrow">
          <MdContent source={data.strapiWarrantyInformation?.content} />
        </Container>
      </Box>
    </Layout>
  )
}

export default WarrantyInformation

export const query = graphql`
  query WarrantyInformation {
    strapiWarrantyInformation {
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      content
    }
  }
`
